import React, {useEffect} from 'react';
import { motion } from "framer-motion";
import FadeIn from "react-lazyload-fadein";
import ReactGA from 'react-ga';

function Profile() {
  const [custom, setCustom] = React.useState(1);
  const lineHeight = 24; //Each text line adds 24px to height

  useEffect(() => {
    setCustom(1);
    setCustom(0);
		ReactGA.initialize('UA-176007228-1');
    ReactGA.pageview('Profile');
  }, []); /*do this on load only once*/

  return (
    <div className="container-fluid">
      <div className="box-container">
        <FadeIn height={400} duration={200}>
        {onLoad =>
          <motion.img className="profile_pic"
          src="/home/website_arsam_picture_smallest.png" alt=""
          draggable="false"
      		key='profile_pic_key'
          transition={{ duration: 1.0, delay: custom * 0.5 }}
          whileHover={{ scaleX: 1.05, scaleY: 1.05}}
          whileTap={{ scale: 0.9 }}
          initial={{ opacity: 0, scale: 1, x: 0, y: 50 }}
          animate={{ opacity: 1, scale: 1, x:0, y: -10 }}
          exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}
          onLoad={onLoad} />
        }
        </FadeIn>
      </div>
      <div className="text-container">
        <motion.h1 className="education-text-h"
        key="/profile_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0 }}>
          Profile
        </motion.h1>
        <motion.p className="education-text-1"
        key="/profile_text_1"
        initial={{ opacity: 0, x:0, y:40 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
          I believe that one of the most important aspects of life is learning and using it to achieve one's full potential.
          Following that belief, I have always sought challenges and new ways to further my knowledge and understanding.
        </motion.p>
        <motion.p className="education-text-1"
        key="/profile_text_2"
        initial={{ opacity: 0, x:0, y: 60 + 4 * lineHeight}}
        animate={{ opacity: 1, x:0, y: 20 + 4 * lineHeight}}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}>
          When I started studying engineering physics, I quickly found a job as a consultant.
          I challenged myself and studied the first year of my master of science in <span style={{color: '#008ae6', fontWeight: 'bold'}}>Austria</span>.
          After my engineering degree, I pursued an MBA in the <span style={{color: '#008ae6', fontWeight: 'bold'}}>United States</span>.
        </motion.p>
        <motion.p className="education-text-1"
        key="/profile_text_3"
        initial={{ opacity: 0, x:0, y: 80 + 8 * lineHeight }}
        animate={{ opacity: 1, x:0, y: 40 + 8 * lineHeight }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}>
          This mentality, combined with genuine passion and excitement for work, gets results:
          I received the <span style={{color: '#008ae6', fontWeight: 'bold'}}>Automation Student 2019</span> award, a <span style={{color: '#008ae6', fontWeight: 'bold'}}>Wallenberg</span> scholarship, and multiple MBA awards including <span style={{color: '#008ae6', fontWeight: 'bold'}}>student of the year</span>.
        </motion.p>
        <motion.p className="education-text-1"
        key="/profile_text_4"
        initial={{ opacity: 0, x:0, y: 80 + 13 * lineHeight }}
        animate={{ opacity: 1, x:0, y: 40 + 13 * lineHeight }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}>
          I continued my journey at ABB's prestigiuous trainee program and since May 2022, I've been working at ABB as a Global Product Manager.
          I am thrilled to be working in a role where I can drive change in an area I am passionate about. Let's digitize the world!
        </motion.p>
      </div>
	  </div>
  );
}

export default Profile;
