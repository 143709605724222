import React, {useEffect} from 'react';
import { motion } from "framer-motion";
import Typist from 'react-typist';
import 'react-typist/dist/Typist.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import FadeIn from "react-lazyload-fadein";
import ReactGA from 'react-ga';

function Homepage() {
  const lineHeight = 24; //Each text line adds 24px to height
  const [[showText, showBackground, showText2], setShow] = React.useState([0, 0.7, 1]);

	useEffect(() => {
		ReactGA.initialize('UA-176007228-1');
    ReactGA.pageview('Homepage');
	}, []);

  return (
    <div className="container-fluid">
      <FadeIn height={400} duration={400}>
      {onLoad =>
        <motion.div className="carousel_container_1"
        key="carousel-container"
        initial={{ opacity: 0, x:500, y:0 }}
        animate={{ opacity: 1, x:0, y:0, backgroundColor: "#010000"}}
        exit={{ opacity: 0, x:500, y:0, transition: {duration: 0.2, staggerChildren: 0.5} }}
        onMouseEnter={() => setShow([80, 0.3, 0])}
        onMouseLeave={() => setShow([0, 0.7, 1])}
        transition={{ duration: 0.5, delay: 0.5 }}
        onLoad={onLoad}>
            <Carousel className="carousel_container_2"
            showArrows={false}
            useKeyboardArrows={true}
            showStatus={false}
            showIndicators={true}
            showThumbs={false}
            stopOnHover={true}
            swipeable={true}
            dynamicHeight={false}
            autoPlay={true}
            infiniteLoop={true}
            interval={6000}>
              <div className="carousel_1">
                <motion.img
                src="/general/traineepodd.png"
                draggable="false"
                animate={{opacity:showBackground}}/>
                <motion.h1 className="carousel_text_1_white"
                key="carousel_popup_1"
                initial={{ opacity: 0, x:0, y: 0 }}
                animate={{ opacity: 1, x:0, y: -showText }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0 }}>
                Listen to our podcast as we talk to people from ABB
                </motion.h1>
                <motion.p className="carousel_text_2_white"
                key="carousel_popup_2"
                initial={{ opacity: 0, x:0, y: -45 }}
                animate={{ opacity: showText2, x:0, y: -45 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1, delay: 0 }}>
                Read more &#8594;
                </motion.p>
                <motion.p className="carousel_text_2_white"
                key="carousel_popup_3"
                initial={{ opacity: 0, x:0, y: 0 }}
                animate={{ opacity: 1, x:0, y: -showText * 1.5 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0 }}>
                During the trainee program at ABB, my trainee colleague and I interviewed different people at ABB about topics ranging from recruitment to prejudice.
                <a className="carousel_link_white" target="_blank" rel="noopener noreferrer" href="https://open.spotify.com/show/3r5m9H75eNEjuniGjvde2P">
                &nbsp;Click here to listen on Spotify.</a>
                </motion.p>
              </div>
              <div className="carousel_1">
                <motion.img
                src="/general/traineegroup.jpg"
                draggable="false"
                animate={{opacity:showBackground}}/>
                <motion.h1 className="carousel_text_1_white"
                key="carousel_popup_1"
                initial={{ opacity: 0, x:0, y: 0 }}
                animate={{ opacity: 1, x:0, y: -showText }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0 }}>
                Accepted to ABB Trainee Program
                </motion.h1>
                <motion.p className="carousel_text_2_white"
                key="carousel_popup_2"
                initial={{ opacity: 0, x:0, y: -45 }}
                animate={{ opacity: showText2, x:0, y: -45 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1, delay: 0 }}>
                Read more &#8594;
                </motion.p>
                <motion.p className="carousel_text_2_white"
                key="carousel_popup_3"
                initial={{ opacity: 0, x:0, y: 0 }}
                animate={{ opacity: 1, x:0, y: -showText * 1.5 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0 }}>
                After having finished my MBA, I got accepted into ABB's renowned trainee program for graduate engineers.
                <a className="carousel_link_white" target="_blank" rel="noopener noreferrer" href="https://new.abb.com/se/jobba-hos-oss/dina-mojligheter/student/stipendier-alde-nilsson/nyutexaminerad/trainee">
                &nbsp;Click here to read more.</a>
                </motion.p>
              </div>
              <div className="carousel_1">
                <motion.img
                src="/general/automation_award.png"
                draggable="false"
                animate={{opacity:showBackground}}/>
                <motion.h1 className="carousel_text_1_white"
                key="carousel_popup_1"
                initial={{ opacity: 0, x:0, y: 0 }}
                animate={{ opacity: 1, x:0, y: -showText }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0 }}>
                Awarded Automation Student 2019
                </motion.h1>
                <motion.p className="carousel_text_2_white"
                key="carousel_popup_2"
                initial={{ opacity: 0, x:0, y: -45 }}
                animate={{ opacity: showText2, x:0, y: -45 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1, delay: 0 }}>
                Read more &#8594;
                </motion.p>
                <motion.p className="carousel_text_2_white"
                key="carousel_popup_3"
                initial={{ opacity: 0, x:0, y: 0 }}
                animate={{ opacity: 1, x:0, y: -showText * 1.5 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0 }}>
                I was awarded Automation Student 2019 for the best automation related master thesis in Sweden.
                <a className="carousel_link_white" target="_blank" rel="noopener noreferrer" href="https://www.automationregion.com/arbete-om-optimering-av-kollaborativa-robotar-vinnare-i-automation-student/#.XZV77UjDPzE.linkedin">
                &nbsp;Click here to read more.</a>
                </motion.p>
              </div>
              <div className="carousel_1">
                <motion.img
                src="/general/react_website.png"
                draggable="false"
                animate={{opacity:showBackground}}/>
                <motion.h1 className="carousel_text_1_white"
                key="carousel_popup_1"
                initial={{ opacity: 0, x:0, y: 0 }}
                animate={{ opacity: 1, x:0, y: -showText }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0 }}>
                Designing a website in React
                </motion.h1>
                <motion.p className="carousel_text_2_white"
                key="carousel_popup_2"
                initial={{ opacity: 0, x:0, y: -45 }}
                animate={{ opacity: showText2, x:0, y: -45 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.1, delay: 0 }}>
                Read more &#8594;
                </motion.p>
                <motion.p className="carousel_text_2_white"
                key="carousel_popup_3"
                initial={{ opacity: 0, x:0, y: 0 }}
                animate={{ opacity: 1, x:0, y: -showText * 1.5 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2, delay: 0 }}>
                During the summer of 2020, I decided to learn how to make a website in React.
                <a className="carousel_link_white" target="_blank" rel="noopener noreferrer" href="https://github.com/arsamshokrian/arsamshokrian">
                &nbsp;Click here to view the source code for my website.</a>
                </motion.p>
              </div>
            </Carousel>
        </motion.div>
      }
      </FadeIn>
      <div className="text-container">
      <motion.h1 className="education-text-h"
      key="/homepage_text_h"
      initial={{ opacity: 0, x:0, y:20 }}
      animate={{ opacity: 1, x:0, y:0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0 }}>
        <Typist
          avgTypingDelay={100}
          startDelay={500}
          cursor={{show:false, blink:false, hideWhenDone: true}}>
    	  	Welcome to my website!
        </Typist>
      </motion.h1>
      <motion.p className="education-text-1"
      key="/homepage_text_1"
      initial={{ opacity: 0, x:0, y:40 }}
      animate={{ opacity: 1, x:0, y:0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.1 }}>
  	  	I'm <span style={{color: '#ff9933', fontWeight: 'bold'}}>Arsam Sahba</span>. I'm an engineer, an MBA graduate, and a product manager at ABB.
      </motion.p>
      <motion.p className="education-text-1"
      key="/homepage_text_2"
      initial={{ opacity: 0, x:0, y: 60 + 2 * lineHeight}}
      animate={{ opacity: 1, x:0, y: 20 + 2 * lineHeight}}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}>
        As an engineer, I have worked on automatization, AI, programming, and physics.
        During my MBA studies, I learned about leadership and management. I started at ABB as a trainee and now I am working in the Process Automation group as Global Product Manager focusing on digital solutions for the Pulp & Paper industry.
      </motion.p>
      <motion.p className="education-text-1"
      key="/homepage_text_3"
      initial={{ opacity: 0, x:0, y: 80 + 7 * lineHeight }}
      animate={{ opacity: 1, x:0, y: 40 + 7 * lineHeight }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}>
  	  	Browse my website to find out more about me, my work, and my ongoing projects! Press the&nbsp;
  	  	<img height={15} src="/website_arsam_logo_alt2.png" alt=""/> icon to return to this page.
      </motion.p>
      </div>
  	</div>
  );
}

export default Homepage;
