import React from 'react';
import ReactDOM from 'react-dom';
import Desktop from './Desktop';
import Mobile from './Mobile';
import * as serviceWorker from './serviceWorker';
import {
	HashRouter as Router
} from 'react-router-dom';
import MediaQuery from 'react-responsive'

ReactDOM.render(
	<React.StrictMode>
		<Router>
	    <MediaQuery minWidth={1100}>
	      <Desktop />
	    </MediaQuery>
	    <MediaQuery maxWidth={1100}>
				<Mobile />
	    </MediaQuery>
		</Router>
	</React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
