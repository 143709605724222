import React, {useEffect} from 'react';
import {
	Link,
	useLocation
} from 'react-router-dom';
import { motion } from "framer-motion";

function Header() {
	const [custom, setCustom] = React.useState(1)
	const [[classname1, classname2, classname3, classname4, classname5], setClassname] = React.useState(['first', 'second', 'third', 'fourth', 'fifth'])
	const location = useLocation();
	const [logoColor, setLogoColor] = React.useState('logo_def')

	useEffect(() => {
		if (location.pathname==='/') {
			setClassname(['first','second', 'third', 'fourth', 'fifth']);
			setLogoColor('logo');
		};
		if (location.pathname==='/Profile') {
			setClassname(['a_selected','second', 'third', 'fourth', 'fifth']);
			setLogoColor('logo_prof');
		};
		if (location.pathname==='/Education') {
			setClassname(['first','a_selected', 'third', 'fourth', 'fifth']);
			setLogoColor('logo_edu');
		};
		if (location.pathname==='/Experience') {
			setClassname(['first','second', 'a_selected', 'fourth', 'fifth']);
			setLogoColor('logo_exp');
		};
		if (location.pathname==='/Awards') {
			setClassname(['first','second', 'third', 'a_selected', 'fifth']);
			setLogoColor('logo_awards');
		};
		if (location.pathname==='/Contact') {
			setClassname(['first','second', 'third', 'fourth', 'a_selected']);
			setLogoColor('logo_cont');
		};
  }, [location.pathname]); // Only re-run the effect if location.pathname changes

  return (
    <header>

	  <motion.div className={logoColor}
		key='logo'
		initial={{ opacity: 0}}
		animate={{ opacity: 1}}
		transition={{ duration: 0.5, delay: 0 }}
		onClick={() => {
			setClassname(['first','second', 'third', 'fourth', 'fifth']);
			setLogoColor('logo');
			}}>
			<Link to="/"
			><a href="/#" id="logo">
	  	<img src="/website_arsam_logo_alt21.png" alt=""
			draggable="false"/>
			</a>
			</Link>
	  </motion.div>

	  <nav>
	  	<ul>
	  		<motion.li className={classname1}
				key='profile_link'
		    initial={{ opacity: 0, x: 0, y: 0}}
		    animate={{ opacity: 1, x:0, y: 0}}
		    transition={{ duration: 0.5, delay: custom * 0}}
	      whileTap={{ scale: 0.9 }}
				onClick={() => {
					setCustom(0);
					setClassname(['a_selected','second', 'third', 'fourth', 'fifth']);
					setLogoColor('logo_prof');
					}}>
	  			<Link to="/Profile">PROFILE</Link>
	  		</motion.li>
	  		<motion.li className={classname2}
				key='education_link'
		    initial={{ opacity: 0, x: 0, y: 0}}
		    animate={{ opacity: 1, x:0, y: 0}}
		    transition={{ duration: 0.5, delay: custom * 0}}
	      whileTap={{ scale: 0.9 }}
				onClick={() => {
					setCustom(0);
					setClassname(['first','a_selected', 'third', 'fourth', 'fifth']);
					setLogoColor('logo_edu');
					}}>
					<Link to="/Education">EDUCATION</Link>
	  		</motion.li>
				<motion.li className={classname3}
				key='experience_link'
		    initial={{ opacity: 0, x: 0, y: 0}}
		    animate={{ opacity: 1, x:0, y: 0}}
		    transition={{ duration: 0.5, delay: custom * 0}}
	      whileTap={{ scale: 0.9 }}
				onClick={() => {
					setCustom(0);
					setClassname(['first','second', 'a_selected', 'fourth', 'fifth']);
					setLogoColor('logo_exp');
					}}>
	  			<Link to="/Experience">EXPERIENCE</Link>
	  		</motion.li>
				<motion.li className={classname4}
				key='awards_link'
		    initial={{ opacity: 0, x: 0, y: 0}}
		    animate={{ opacity: 1, x:0, y: 0}}
		    transition={{ duration: 0.5, delay: custom * 0}}
	      whileTap={{ scale: 0.9 }}
				onClick={() => {
					setCustom(0);
					setClassname(['first','second', 'third', 'a_selected', 'fifth']);
					setLogoColor('logo_awards');
					}}>
	  			<Link to="/Awards">AWARDS</Link>
	  		</motion.li>
	  		<motion.li className={classname5}
				key='contact_link'
		    initial={{ opacity: 0, x: 0, y: 0}}
		    animate={{ opacity: 1, x:0, y: 0}}
		    transition={{ duration: 0.5, delay: custom * 0}}
	      whileTap={{ scale: 0.9 }}
				onClick={() => {
					setCustom(0);
					setClassname(['first','second', 'third', 'fourth', 'a_selected']);
					setLogoColor('logo_cont');
					}}>
	  			<Link to="/Contact">CONTACT</Link>
	  		</motion.li>
	  	</ul>
	  </nav>

	</header>
  );
}

export default Header;
