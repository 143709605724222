import React, {useEffect} from 'react';
import { motion } from "framer-motion";
import ReactGA from 'react-ga';

function Contact() {
  const [custom, setCustom] = React.useState(1);

  useEffect(() => {
    setCustom(1);
    setCustom(0);
		ReactGA.initialize('UA-176007228-1');
    ReactGA.pageview('Contact');
  }, []); /*do this on load only once*/

  return (
    <div className="container-fluid">
      <div className="box-container">
        <a className="text_link_1" target="_blank" rel="noopener noreferrer" href={`mailto:${'arsam.sahba@gmail.com'}`}>
          <motion.div className="box1_cont"
            key="/gmail_pic"
            transition={{ duration: 0.5, delay: custom * 0.25 }}
            whileHover={{ scaleX: 1.05, scaleY: 1.05, backgroundColor: '#dc6fb8'}} //this is stupid, for some reason scale can't be changed twice
            whileTap={{ scaleX: 0.9, scaleY: 0.9 }}
            initial={{ opacity: 0, scale: 1, x: 0, y: 50 }}
            animate={{ opacity: 1, scale: 1, x:0, y: -10 }}
            exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}
          >
          </motion.div>
        </a>
        <a className="text_link_1" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/arsamsahba/">
          <motion.div className="box2_cont"
            key="/linkedin_pic"
            transition={{ duration: 0.5, delay: custom * 0.5 }}
            whileHover={{ scaleX: 1.05, scaleY: 1.05, backgroundColor: '#dc6fb8'}} //this is stupid, for some reason scale can't be changed twice
            initial={{ opacity: 0, scale: 1, x: 0, y: 50 }}
            animate={{ opacity: 1, scale: 1, x:0, y: -10 }}
            exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}
          >
          </motion.div>
        </a>
        <a className="text_link_1" target="_blank" rel="noopener noreferrer" href="https://github.com/arsamshokrian">
          <motion.div className="box3_cont"
            key="github_pic"
            transition={{ duration: 0.5, delay: custom * 0.75 }}
            whileHover={{ scaleX: 1.05, scaleY: 1.05, backgroundColor: '#dc6fb8'}} //this is stupid, for some reason scale can't be changed twice
            initial={{ opacity: 0, scale: 1, x: 0, y: 50 }}
            animate={{ opacity: 1, scale: 1, x:0, y: -10 }}
            exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}
          >
      		{/*
            <Particles
              				params={{
              					particles: {
                          number: {
                            value: 20,
                            density: {
                              enable: false,
                              value_area: 600
                            }
                          },
                          color: {
                            value: '#ffffff'
                          },
                          shape: {
                      			//*
                            type: 'circle',
                            stroke: {
                              width: 0,
                              color: '#ffffff'
                            }*
                      			type: 'image',
                      			image: {
                      				src: '/icons/github3.png',
                      				width: 1,
                      				height: 1
                      			},
                      			stroke: {
                              width: 0,
                              color: '#000000'
                            }
                          },
                          opacity: {
                            value: 0.2,
                            random: false,
                            anim: {
                              enable: false,
                              speed: 1,
                              opacity_min: 0.1,
                              sync: false
                            }
                          },
                          size: {
                            value: 10,
                            random: true,
                            anim: {
                              enable: false,
                              speed: 40,
                              size_min: 0.1,
                              sync: false
                            }
                          },
                          line_linked: {
                            enable: false,
                            distance: 150,
                            color: '#ffffff',
                            opacity: 0.4,
                            width: 1
                          },
                          move: {
                            enable: true,
                            speed: 5,
                            direction: 'left',
                            random: true,
                            straight: true,
                            out_mode: 'out',
                            bounce: false,
                            attract: {
                              enable: false,
                              rotateX: 600,
                              rotateY: 1200
                            }
                          }
                        },
                        interactivity: {
                          detect_on: 'canvas',
                          events: {
                            onhover: {
                              enable: true,
                              mode: 'repulse'
                            },
                            onclick: {
                              enable: false,
                              mode: 'repulse'
                            },
                            resize: true
                          },
                          modes: {
                            grab: {
                              distance: 200,
                              line_linked: {
                                opacity: 1
                              }
                            },
                            bubble: {
                              distance: 400,
                              size: 40,
                              duration: 2,
                              opacity: 8,
                              speed: 3
                            },
                            repulse: {
                              distance: 50,
                              duration: 0.4
                            },
                            push: {
                              particles_nb: 4
                            },
                            remove: {
                              particles_nb: 2
                            }
                          }
                        },
                        retina_detect: true
                      }}
              				style={{
              					height: '100%',
              					width: '100%'
              				}}
            			/>
                  */}
          </motion.div>
        </a>
      </div>
      <div className="text-container">
        <motion.h1 className="education-text-h"
        key="/contact_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0 }}>
          Contact
        </motion.h1>
        <motion.p className="education-text-1"
        key="/contaxt_text_1"
        initial={{ opacity: 0, x:0, y:50 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0 }}>
          If you wish to contact me you can reach me through my LinkedIn, GitHub, or send an email to me through the links to the right.
        </motion.p>
        {/*
        <div className="images_table">
          <div className="images_row">
            <motion.div className="home_icons"
              key="/home_icons1"
              transition={{ duration: 0.5 }}
              whileHover={{ backgroundColor: '#ff9933' }}
              whileTap={{ scale: 0.9 }}
              initial={{ opacity: 0, scale: 1, x: 0, y: -10 }}
              animate={{ opacity: 1, scale: 1, x: 0, y: 0 }}
              exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}>
              <FadeIn height={400} duration={500}>
              {onLoad =>
                <img src="/icons/gmail2.png" alt=""
                onLoad={onLoad} />
              }
              </FadeIn>
            </motion.div>
            <motion.div className="home_icons"
              key="/home_icons2"
              transition={{ duration: 0.5 }}
              whileHover={{ backgroundColor: '#ff9933' }}
              whileTap={{ scale: 0.9 }}
              initial={{ opacity: 0, scale: 1, x: 0, y: -10 }}
              animate={{ opacity: 1, scale: 1, x: 0, y: 0 }}
              exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}>
              <FadeIn height={400} duration={500}>
              {onLoad =>
                <img src="/icons/linkedin3.png" alt=""
                onLoad={onLoad} />
              }
              </FadeIn>
            </motion.div>
            <motion.div className="home_icons"
              key="/home_icons3"
              transition={{ duration: 0.5 }}
              whileHover={{ backgroundColor: '#ff9933' }}
              whileTap={{ scale: 0.9 }}
              initial={{ opacity: 0, scale: 1, x: 0, y: -10 }}
              animate={{ opacity: 1, scale: 1, x: 0, y: 0 }}
              exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}>
              <FadeIn height={400} duration={500}>
              {onLoad =>
                <img src="/icons/github2.png" alt=""
                onLoad={onLoad} />
              }
              </FadeIn>
            </motion.div>
          </div>
        </div>
        */}
      </div>
	  </div>
  );
}

export default Contact;
