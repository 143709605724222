import React, {useEffect} from 'react';
import { motion } from "framer-motion";
import FadeIn from "react-lazyload-fadein";
import Fade from 'react-reveal/Fade';
import ReactGA from 'react-ga';

function ProfileMobile() {

	useEffect(() => {
		ReactGA.initialize('UA-176007228-1');
    ReactGA.pageview('Profile');
	}, []);

  return (
    <div className="container-mobile">
    <Fade right duration={1000}>
      <div className="profile_pic_mobile_container">
        <div className="profile_pic_mobile">
          <FadeIn height={400} duration={400}>
          {onLoad =>
            <motion.img
            src="/home/website_arsam_picture_smallest.png" alt=""
        		key='profile_pic_key'
            draggable="false"
            transition={{ duration: 1.0, delay: 0 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}
            onLoad={onLoad} />
          }
          </FadeIn>
        </div>
      </div>
    </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        key="/profile_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
          Profile
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/profile_text_1"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          I believe that one of the most important aspects of life is learning and using it to achieve one's full potential.
          Following that belief, I have always sought challenges and new ways to further my knowledge and understanding.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/profile_text_2"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          When I started studying engineering physics, I quickly found a job as a consultant.
          I challenged myself and studied the first year of my master of science in <span style={{color: '#008ae6', fontWeight: 'bold'}}>Austria</span>.
          After my engineering degree, I pursued an MBA in the <span style={{color: '#008ae6', fontWeight: 'bold'}}>United States</span>.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/profile_text_3"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          This mentality, combined with genuine passion and excitement for work, gets results:
          I received the <span style={{color: '#008ae6', fontWeight: 'bold'}}>Automation Student 2019</span> award, a <span style={{color: '#008ae6', fontWeight: 'bold'}}>Wallenberg</span> scholarship, and multiple MBA awards including <span style={{color: '#008ae6', fontWeight: 'bold'}}>student of the year</span>.
          </motion.p>
          <motion.p className="education-text-1-mobile-end"
          key="/profile_text_4"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
					I continued my journey at ABB's prestigiuous trainee program and since May 2022, I've been working at ABB as a Global Product Manager.
          I am thrilled to be working in a role where I can drive change in an area I am passionate about. Let's digitize the world!
          </motion.p>
      </div>
	  </div>
  );
}

export default ProfileMobile;
