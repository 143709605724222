import React, {useEffect} from 'react';
import {
	Link,
	useLocation
} from 'react-router-dom';
import { motion } from "framer-motion";
import Drawer from 'react-motion-drawer';

function HeaderMobile() {
	const location = useLocation();
	const [logoColor, setLogoColor] = React.useState('logo_def')
	const [menuColor, setMenuColor] = React.useState('menu_def')
	const [openRight, setConst] = React.useState(false)
	const drawerStyle = {overlayColor: "rgba(255,255,255,0.6)", background: "#F9F9F9", boxShadow: "rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px"};
	const drawerProps = {
	      overlayColor: "rgba(255,255,255,0.6)",
	      drawerStyle
	    };

	useEffect(() => {
		if (location.pathname==='/') {
			setLogoColor('logo');
			setMenuColor('menu');
		};
		if (location.pathname==='/Profile') {
			setLogoColor('logo_prof');
			setMenuColor('menu_prof');
		};
		if (location.pathname==='/Education') {
			setLogoColor('logo_edu');
			setMenuColor('menu_edu');
		};
		if (location.pathname==='/Experience') {
			setLogoColor('logo_exp');
			setMenuColor('menu_exp');
		};
		if (location.pathname==='/Awards') {
			setLogoColor('logo_awards');
			setMenuColor('menu_awards');
		};
		if (location.pathname==='/Contact') {
			setLogoColor('logo_cont');
			setMenuColor('menu_cont');
		};
  }, [location.pathname]); // Only re-run the effect if location.pathname changes

  return (
    <header>

	  <motion.div className={logoColor}
		key='logo'
		initial={{ opacity: 0}}
		animate={{ opacity: 1}}
		transition={{ duration: 0.5, delay: 0 }}
		whileTap={{ opacity: 0.75 }}
		onClick={() => {
			setLogoColor('logo');
			setMenuColor('menu');
			}}>
			<Link to="/"
			><a href="/#" id="logo">
	  	<img draggable="false" src="/website_arsam_logo_alt21.png" alt=""/>
			</a>
			</Link>
	  </motion.div>
		<motion.div className={menuColor}
		key='menu'
		initial={{ opacity: 0}}
		animate={{ opacity: 1}}
		transition={{ duration: 0.5, delay: 0 }}
		whileTap={{ opacity: 0.75 }}
		onClick={() => {
			setConst(!openRight)
			}}>
			{
				// eslint-disable-next-line
			}<a id="menu">
			</a>
	  </motion.div>
		<Drawer
      right
      width={200}
      {...drawerProps}
			fadeOut
      open={openRight}
      onChange={open => setConst(!openRight)}
      noTouchOpen={true}
      noTouchClose={true}
    >
			<div className='drawer-container'>
				<Link to="/Profile" className="drawer_link"
				onClick={() => {
					setLogoColor('logo_prof');
					setMenuColor('menu_prof');
					setConst(!openRight);
				}}>
				<h1 className="drawer-text">
				Profile
				</h1>
				</Link>
				<Link to="/Education" className="drawer_link"
				onClick={() => {
					setLogoColor('logo_edu');
					setMenuColor('menu_edu');
					setConst(!openRight);
				}}>
				<h1 className="drawer-text">
				Education
				</h1>
				</Link>
				<Link to="/Experience" className="drawer_link"
				onClick={() => {
					setLogoColor('logo_exp');
					setMenuColor('menu_exp');
					setConst(!openRight);
				}}>
				<h1 className="drawer-text">
				Experience
				</h1>
				</Link>
				<Link to="/Awards" className="drawer_link"
				onClick={() => {
					setLogoColor('logo_awards');
					setMenuColor('menu_awards');
					setConst(!openRight);
				}}>
				<h1 className="drawer-text">
				Awards
				</h1>
				</Link>
				<Link to="/Contact" className="drawer_link"
				onClick={() => {
					setLogoColor('logo_cont');
					setMenuColor('menu_cont');
					setConst(!openRight);
				}}>
				<h1 className="drawer-text">
				Contact
				</h1>
				</Link>
			</div>
    </Drawer>
		{/*
	  <nav>
    <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <img height={35} src="/website_arsam_menu.png" alt=""/>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem component={Link} to="/Profile"
    		onClick={() => {
    			setClassname(['a_selected','second', 'third', 'fourth', 'fifth']);
    			setLogoColor('logo_prof');
    			}}>Profile</MenuItem>
      </Menu>
	  </nav>
		*/}
	</header>
  );
}

export default HeaderMobile;
