import React, {useEffect} from 'react';
import { motion } from "framer-motion";
import FadeIn from "react-lazyload-fadein";
import Fade from 'react-reveal/Fade';
import ReactGA from 'react-ga';

function EducationMobile() {
  
	useEffect(() => {
		ReactGA.initialize('UA-176007228-1');
    ReactGA.pageview('Homepage');
	}, []);

  return (
    <div className="container-mobile">
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/education_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
          Education
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/education_text_1"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          After several years of higher education, I have attained one undergraduate and two graduate degrees.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/education_text_2"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          I received a <span style={{color:'#00b33c', fontWeight:'bold'}}>Bachelor of Science</span> from Chalmers University of Technology, where I also completed my <span style={{color:'#00b33c', fontWeight:'bold'}}>Master of Science</span>.
          After finishing my engineering degree, I started my studies at the W.A. Franke College of Business at Northern Arizona University where I obtained a <span style={{color:'#00b33c', fontWeight:'bold'}}>Master of Business Administration</span>.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/education_text_3"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          My BSc was in Engineering Physics, my MSc in Systems, Control, and Mechatronics, while my MBA had an Applied Management emphasis.
          </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="education_mobile_container"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <div className="education_mobile">
            <FadeIn height={400} duration={200}>
            {onLoad =>
              <motion.img style={{height: 150, marginTop: 50}}
              src="/education/chalmers_logo4.png" alt=""
              draggable="false"
              onLoad={onLoad} />
            }
            </FadeIn>
        </div>
      </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/education_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	Chalmers University of Technology
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/education_text_1"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          The engineering program I studied at Chalmers was in Engineering Physics and included both a BSc and a MSc.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/education_text_2"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          During these degrees, I learned about technical subjects concerning advanced mathematics and physics. In general, I learned how to solve complex problems in different fields under intense time pressure.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/education_text_3"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
            The program included two theses, one each for the BSc and the MSc. For my bachelor's thesis, I programmed autonomous robots so they would avoid colliding with each other in an intersecting path. Read about it <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#00b33c', fontWeight:'bold'}} href="https://odr.chalmers.se/handle/20.500.12380/250980">
            here</a>.
            For the master's thesis, I developed an adaptive collaborative robot in ABB's robot simulation software RobotStudio. You can read about it <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#00b33c', fontWeight:'bold'}} href="https://odr.chalmers.se/handle/20.500.12380/300282">
            here</a> and watch a video demonstration of it <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#00b33c', fontWeight:'bold'}} href="https://www.linkedin.com/posts/arsamshokrian_ai-ml-abb-activity-6575274499032395776-V-GT">
            here</a>. The master thesis was presented in the annual Hannover fair in Germany, one of the biggest technological fairs in the world.
          </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="education_mobile_container"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <div className="education_mobile">
              <img style={{height: 75, marginTop: 87.5}}
              src="/education/graz_logo4.png" alt=""
              draggable="false"/>
        </div>
      </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/education_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	Graz University of Technology
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/education_text_1"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          During the first year of my Master of Science, I was able to take part of the <span style={{color:'#00b33c', fontWeight:'bold'}}>ERASMUS+ programme</span> and study a year in Graz, Austria.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/education_text_2"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          I studied in Technische Universität Graz, where I took engineering courses related to my degree. Some of the courses were Adaptive Systems, Embedded Systems, and Optimization for Computer Science.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/education_text_3"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          Living and studying in a foreign country proved to be an invaluable experience both personally and professionally.
          </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="education_mobile_container"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <div className="education_mobile">
              <img style={{height: 100, marginTop: 75}}
              src="/education/nau_logo4.png" alt=""
              draggable="false"/>
        </div>
      </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/education_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	Northern Arizona University
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/education_text_1"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          After I completed my engineering degree, I decided to complement my technical skills with managerial and business knowledge.
          I therefore chose to pursue an MBA, and I wanted to do it in the country famous for its managerial university degrees: the U.S.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/education_text_2"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
            I chose <span style={{color:'#00b33c', fontWeight:'bold'}}>Northern Arizona University</span> because of its reputation, location, and opportunities.
            It proved to be a good decision as I got to experience a beautiful city where I was able to expand my knowledge and skills.
          </motion.p>
          <motion.p className="education-text-1-mobile-end"
          key="/education_text_3"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          At NAU, I studied many courses related to business, management, leadership, accounting, and finance.
          Among other things, I learned how to lead and develop people, how to use lean principles, and how to manage and analyze a company financially.
          </motion.p>
      </div>
	  </div>
  );
}

export default EducationMobile;
