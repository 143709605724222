import React, {useEffect} from 'react';
import { motion } from "framer-motion";
import Fade from 'react-reveal/Fade';
import FadeIn from "react-lazyload-fadein";
import ReactGA from 'react-ga';

function ContactMobile() {

	useEffect(() => {
		ReactGA.initialize('UA-176007228-1');
    ReactGA.pageview('Contact');
	}, []);

  return (
    <div className="container-mobile">
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/contact_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
          Contact
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/contact_text_1"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          If you wish to contact me you can reach me through my LinkedIn, GitHub, or send an email to me through the links down below.
          </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="box-container-mobile"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <a className="text_link_1" target="_blank" rel="noopener noreferrer" href={`mailto:${'arsam.sahba@gmail.com'}`}>
        <div className="contact_mobile_container">
          <div className="education_mobile">
            <FadeIn height={400} duration={400}>
            {onLoad =>
                <img style={{height: 60, marginTop: 95}}
                src="/icons/gmail3.png" alt=""
                draggable="false"
                onLoad={onLoad}/>
            }
            </FadeIn>
          </div>
        </div>
        </a>
        <a className="text_link_1" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/arsamsahba/">
        <div className="contact_mobile_container">
          <div className="education_mobile">
          <FadeIn height={400} duration={400}>
          {onLoad =>
                <img style={{height: 60, marginTop: 95}}
                src="/icons/linkedin4.png" alt=""
                draggable="false"
                onLoad={onLoad}/>
            }
            </FadeIn>
          </div>
        </div>
        </a>
        <a className="text_link_1" target="_blank" rel="noopener noreferrer" href="https://github.com/arsamshokrian">
        <div className="contact_mobile_container">
          <div className="education_mobile">
          <FadeIn height={400} duration={400}>
          {onLoad =>
                <img style={{height: 70, marginTop: 90}}
                src="/icons/github3.png" alt=""
                draggable="false"
                onLoad={onLoad}/>
            }
            </FadeIn>
          </div>
        </div>
        </a>
      </motion.div>
      </Fade>
	  </div>
  );
}

export default ContactMobile;
