import React, {useEffect} from 'react';
import { motion } from "framer-motion";
import ReactGA from 'react-ga';

function ChoosePara(status) {
  const lineHeight = 24; //Each text line adds 24px to height
  switch (status) {
    case 0:
      return <div className="text-container">
      <motion.h1 className="education-text-h"
      key="/education_text_h"
      initial={{ opacity: 0, x:0, y:20 }}
      animate={{ opacity: 1, x:0, y:0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0 }}>
  	  	Education
      </motion.h1>
      <motion.p className="education-text-1"
      key="/education_text1"
      initial={{ opacity: 0, x:0, y:40 }}
      animate={{ opacity: 1, x:0, y:0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.1 }}>
  	  	After several years of higher education, I have attained one undergraduate and two graduate degrees.
      </motion.p>
      <motion.p className="education-text-1"
      key="/education_text2"
      initial={{ opacity: 0, x:0, y: 60 + 2 * lineHeight}}
      animate={{ opacity: 1, x:0, y: 20 + 2 * lineHeight}}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}>
  	  	I received a <span style={{color:'#00b33c', fontWeight:'bold'}}>Bachelor of Science</span> from Chalmers University of Technology, where I also completed my <span style={{color:'#00b33c', fontWeight:'bold'}}>Master of Science</span>.
        After finishing my engineering degree, I started my studies at the W.A. Franke College of Business at Northern Arizona University where I obtained a <span style={{color:'#00b33c', fontWeight:'bold'}}>Master of Business Administration</span>.
      </motion.p>
      <motion.p className="education-text-1"
      key="/education_text3"
      initial={{ opacity: 0, x:0, y: 80 + 7 * lineHeight }}
      animate={{ opacity: 1, x:0, y: 40 + 7 * lineHeight }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}>
        My BSc was in Engineering Physics, my MSc in Systems, Control, and Mechatronics, while my MBA had an Applied Management emphasis.
      </motion.p>
      </div>
    case 1:
      return <div className="text-container">
      <motion.h1 className="education-text-h"
      key="/chalmers_text_h"
      initial={{ opacity: 0, x:0, y:20 }}
      animate={{ opacity: 1, x:0, y:0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0 }}>
  	  	Chalmers University of Technology
      </motion.h1>
      <motion.p className="education-text-2"
      key="/chalmers_text1"
      initial={{ opacity: 0, x:0, y:40 }}
      animate={{ opacity: 1, x:0, y:0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.1 }}>
  	  	The engineering program I studied at Chalmers was in Engineering Physics and included both a BSc and a MSc.
      </motion.p>
      <motion.p className="education-text-1"
      key="/chalmers_text2"
      initial={{ opacity: 0, x:0, y: 103 + 2 * lineHeight}}
      animate={{ opacity: 1, x:0, y: 63 + 2 * lineHeight}}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}>
  	  	In my technical subjects concerning advanced mathematics and physics, I learned how to solve complex problems in different fields under intense time pressure.
        This knowledge and experience has enabled me to tackle any challenge with belief in myself and my ability to produce desirable results.
      </motion.p>
      <motion.p className="education-text-1"
      key="/chalmers_text3"
      initial={{ opacity: 0, x:0, y: 123 + 7 * lineHeight }}
      animate={{ opacity: 1, x:0, y: 83 + 7 * lineHeight }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}>
        The program included two theses, one each for the BSc and the MSc. For my bachelor's thesis, I programmed autonomous robots so they would avoid colliding with each other in an intersecting path. Read about it <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#00b33c', fontWeight:'bold'}} href="https://odr.chalmers.se/handle/20.500.12380/250980">
        here</a>.
        For the master's thesis, I developed an adaptive collaborative robot in ABB's robot simulation software RobotStudio. You can read about it <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#00b33c', fontWeight:'bold'}} href="https://odr.chalmers.se/handle/20.500.12380/300282">
        here</a> and watch a video demonstration of it <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#00b33c', fontWeight:'bold'}} href="https://www.linkedin.com/posts/arsamshokrian_ai-ml-abb-activity-6575274499032395776-V-GT">
        here</a>. The master thesis was presented in the annual Hannover fair in Germany, one of the biggest technological fairs in the world.
      </motion.p>
      </div>
    case 2:
     return <div className="text-container">
     <motion.h1 className="education-text-h"
     key="/graz_text_h"
     initial={{ opacity: 0, x:0, y:20 }}
     animate={{ opacity: 1, x:0, y:0 }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.5, delay: 0 }}>
       Graz University of Technology
     </motion.h1>
     <motion.p className="education-text-2"
     key="/graz_text"
     initial={{ opacity: 0, x:0, y:40 }}
     animate={{ opacity: 1, x:0, y:0 }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.5, delay: 0.1 }}>
       During the first year of my Master of Science, I participated in the <span style={{color:'#00b33c', fontWeight:'bold'}}>ERASMUS+ programme</span> and studied a year in Graz, Austria.
     </motion.p>
     <motion.p className="education-text-1"
     key="/graz_text2"
     initial={{ opacity: 0, x:0, y: 123 + 1 * lineHeight}}
     animate={{ opacity: 1, x:0, y: 83 + 1 * lineHeight}}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.5, delay: 0.2 }}>
       I studied in Technische Universität Graz, where I took engineering courses related to my degree. Some of the courses were Adaptive Systems, Embedded Systems, and Optimization for Computer Science.
     </motion.p>
     <motion.p className="education-text-1"
     key="/graz_text3"
     initial={{ opacity: 0, x:0, y: 143 + 5 * lineHeight }}
     animate={{ opacity: 1, x:0, y: 103 + 5 * lineHeight }}
     exit={{ opacity: 0 }}
     transition={{ duration: 0.5, delay: 0.3 }}>
       Living and studying in a foreign country proved to be an invaluable experience both personally and professionally.
     </motion.p>
     </div>
    case 3:
      return <div className="text-container">
      <motion.h1 className="education-text-h"
      key="/nau_text_h"
      initial={{ opacity: 0, x:0, y:20 }}
      animate={{ opacity: 1, x:0, y:0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0 }}>
  	  	Northern Arizona <br/> University
      </motion.h1>
      <motion.p className="education-text-2"
      key="/nau_text"
      initial={{ opacity: 0, x:0, y:50 }}
      animate={{ opacity: 1, x:0, y:0 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.1 }}>
  	  	After I completed my engineering degree, I decided to complement my technical skills with managerial and business knowledge.
        I therefore chose to pursue an MBA, and I wanted to do it in the country famous for its managerial university degrees: the U.S.
      </motion.p>
      <motion.p className="education-text-1"
      key="/nau_text2"
      initial={{ opacity: 0, x:0, y: 123 + 3 * lineHeight}}
      animate={{ opacity: 1, x:0, y: 83 + 3 * lineHeight}}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.2 }}>
        I chose <span style={{color:'#00b33c', fontWeight:'bold'}}>Northern Arizona University</span> because of its reputation, location, and opportunities.
        It proved to be a good decision as I got to experience a beautiful city where I was able to expand my knowledge and skills.
      </motion.p>
      <motion.p className="education-text-1"
      key="/nau_text3"
      initial={{ opacity: 0, x:0, y: 143 + 7 * lineHeight }}
      animate={{ opacity: 1, x:0, y: 103 + 7 * lineHeight }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}>
        At NAU, I studied many courses related to business, management, leadership, accounting, and finance.
        Among other things, I learned how to lead and develop people, how to use lean principles, and how to manage and analyze a company financially.
      </motion.p>
      </div>
    default:
      return null;
  };
}

function Education() {
  const [status, setStatus] = React.useState(0);
  const [custom, setCustom] = React.useState(1);
  const [[sizeBox1, sizeBox2, sizeBox3], setSizeVar] = React.useState([1,1,1]);

  useEffect(() => {
    setCustom(1);
    setCustom(0);
		ReactGA.initialize('UA-176007228-1');
    ReactGA.pageview('Education');
  }, []); /*do this on load only once*/

  return (
    <div className="container-fluid">
      <div className="box-container">
        <motion.div className="box1_edu"
          key="/chalmers_pic"
          transition={{ duration: 0.5, delay: custom * 0.25 }}
          whileHover={{ scaleX: 1.05, scaleY: 1.05, backgroundColor: '#008000'}}
          initial={{ opacity: 0, scale: 1, x: 0, y: 50 }}
          animate={{ opacity: 1, scale: sizeBox1, x:0, y: -10 }}
          exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}
          onClick={() => {
            (status !== 1) ? setStatus(1) : setStatus(0);
            (sizeBox1 === 1.1) ? setSizeVar([1,1,1]) : setSizeVar([1.1,1,1]);
          }}
        >
        </motion.div>

        <motion.div className="box2_edu"
          key="/graz_pic"
          transition={{ duration: 0.5, delay: custom * 0.5 }}
          whileHover={{ scaleX: 1.05, scaleY: 1.05, backgroundColor: '#008000'}}
          initial={{ opacity: 0, scale: 1, x: 0, y: 50 }}
          animate={{ opacity: 1, scale: sizeBox2, x:0, y: -10 }}
          exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}
          onClick={() => {
            (status !== 2) ? setStatus(2) : setStatus(0);
            (sizeBox2 === 1.1) ? setSizeVar([1,1,1]) : setSizeVar([1,1.1,1]);
          }}
        >
        </motion.div>

        <motion.div className="box3_edu"
          key="/nau_pic"
          transition={{ duration: 0.5, delay: custom * 0.75 }}
          whileHover={{ scaleX: 1.05, scaleY: 1.05, backgroundColor: '#008000'}}
          initial={{ opacity: 0, scale: 1, x: 0, y: 50 }}
          animate={{ opacity: 1, scale: sizeBox3, x:0, y: -10 }}
          exit={{ opacity: 0, scale: 1, transition: {staggerChildren: 0.5} }}
          onClick={() => {
            (status !== 3) ? setStatus(3) : setStatus(0);
            (sizeBox3 === 1.1) ? setSizeVar([1,1,1]) : setSizeVar([1,1,1.1]);
          }}
        >
        </motion.div>
      </div>

      {ChoosePara(status)}
	  </div>
  );
}

export default Education;
