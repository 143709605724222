import React, {useEffect} from 'react';
import { motion } from "framer-motion";
import FadeIn from "react-lazyload-fadein";
import Fade from 'react-reveal/Fade';
import ReactGA from 'react-ga';

function ExperienceMobile() {

	useEffect(() => {
		ReactGA.initialize('UA-176007228-1');
    ReactGA.pageview('Experience');
	}, []);

  return (
    <div className="container-mobile">
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/experience_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
          Experience
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_1"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          During my studies I seized the opportunity to accumulate work experience as a consultant, a mentor, a thesis worker, and a research assistant.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_2"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          I worked as a consultant in <span style={{color:'#ff5c33', fontWeight:'bold'}}>Virtual Manufacturing Sweden AB</span>, a mentor for foreign exchange students at <span style={{color:'#ff5c33', fontWeight:'bold'}}>Chalmers University of Technology</span>, a thesis worker at <span style={{color:'#ff5c33', fontWeight:'bold'}}>ABB</span>, and a research assistant in the <span style={{color:'#ff5c33', fontWeight:'bold'}}>W.A. Franke College of Business</span> at NAU.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_3"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          After my studies, I started working as a Graduate Engineering Trainee at <span style={{color:'#ff5c33', fontWeight:'bold'}}>ABB</span>, and then as a Digital Project Manager, and since May 2022 as a Global Product Manager. The work experience I have gathered has provided me with tools and knowledge I use every single day.
          </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="experience_mobile_container"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <div className="education_mobile">
            <FadeIn height={400} duration={200}>
            {onLoad =>
              <motion.img style={{height: 75, marginTop: 87.5}}
              src="/experience/website_ABB_logo3.png" alt=""
              draggable="false"
              onLoad={onLoad} />
            }
            </FadeIn>
        </div>
      </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/experience_text_h_2"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	ABB
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_4"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          I was employed by <span style={{color:'#ff5c33', fontWeight:'bold'}}>ABB</span> for my master's thesis. The thesis was focused on collaborative robot simulations in RobotStudio and using reinforcement learning methods to improve efficiency and effectiveness.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_5"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          After my MBA, I started working as a <span style={{color:'#ff5c33', fontWeight:'bold'}}>Graduate Engineering Trainee</span> at ABB. I worked in three different positions over the span of eight months. Responsibilities included establishing and improving the Advanced Services portfolio at the Process Automation division, developing a financial data analytics tool in Power BI at Operations in Robotics, and conducting a market study to determine strategic initiatives related to circular economy and sustainability in the After Sales division at Motion.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_6"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          After the trainee program, I began as a <span style={{color:'#ff5c33', fontWeight:'bold'}}>Digital Project Manager</span> within the Process Automation business area in ABB. A year later, I moved on to the role of a <span style={{color:'#ff5c33', fontWeight:'bold'}}>Global Product Manager</span>, and I became responsible for ABB's digital solutions for testing data in Pulp & Paper mills.
          </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="experience_mobile_container"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <div className="education_mobile">
              <img style={{height: 75, marginTop: 87.5}}
              src="/experience/website_virtual_logo.png" alt=""
              draggable="false"/>
        </div>
      </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/experience_text_h_3"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	Virtual Manufacturing
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_7"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          After a couple of years at Chalmers University of Technology, I was able to get a part-time job as a consultant at <span style={{color:'#ff5c33', fontWeight:'bold'}}>Virtual Manufacturing</span> in Gothenburg.
          I started working there in the summer of 2016 and was tasked with investigating the use of BLE Beacons to gather indoor tracking data.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_8"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
            I did research about the beacons and had to teach myself how to build apps for iOS and Android.
            I used <span style={{color:'#ff5c33', fontWeight:'bold'}}>Swift</span> and <span style={{color:'#ff5c33', fontWeight:'bold'}}>Java</span> to develop apps in <span style={{color:'#ff5c33', fontWeight:'bold'}}>Xcode</span> and <span style={{color:'#ff5c33', fontWeight:'bold'}}>Android Studios</span> that used the beacons for indoor tracking.
            Afterwards, I sent the tracking data to a computer where it was visaulized as a heatmap in Visual Components.
            Watch a demonstration video <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#ff5c33', fontWeight:'bold'}} href="https://www.linkedin.com/posts/arsamshokrian_the-first-of-two-larger-projects-i-worked-activity-6537604057429757952-i8oe">
            here</a>.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_9"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          Furthermore, I managed a project dedicated to Augmented Reality and the use of <span style={{color:'#ff5c33', fontWeight:'bold'}}>Microsoft Hololens</span>.
          I created several use cases where 3D images were superimposed on 2D surfaces. A video showcasing these use cases can be viewed <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#ff5c33', fontWeight:'bold'}} href="https://www.linkedin.com/feed/update/urn:li:activity:6537606565078290432/">
          here</a>.
          </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="experience_mobile_container"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <div className="education_mobile">
              <img style={{height: 100, marginTop: 75}}
              src="/experience/website_circ_logo5.png" alt=""
              draggable="false"/>
        </div>
      </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/experience_text_h_4"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	Chalmers International Reception Committee
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_10"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          When I came back to Gothenburg after having spent a year abroad in Austria, I decided I wanted to help foreign students who were in the same situation I had been in.
          Therefore, I signed up as a mentor for incoming exchange students at my university in <span style={{color:'#ff5c33', fontWeight:'bold'}}>Chalmers International Reception Committee</span>.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_11"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          I worked as a volunteer for the organization and I was responsible for over 20 foreign exchange students.
          I helped them with any issues they faced regarding housing or living in Sweden. I also organized activities for them to socialize and get to know each other and the city.
          </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="experience_mobile_container"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <div className="education_mobile">
              <img style={{height: 150, marginTop: 50}}
              src="/awards/nau_franke.png" alt=""
              draggable="false"/>
        </div>
      </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/experience_text_h_5"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	W.A. Franke College of Business
        </motion.h1>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_12"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
          During my MBA at Northern Arizona University, I also worked at the university as a <span style={{color:'#ff5c33', fontWeight:'bold'}}>Graduate Research and Teaching Assistant</span>.
          </motion.p>
          <motion.p className="education-text-1-mobile"
          key="/experience_text_13"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
            In my role at the university, I primarily helped students in a <span style={{color:'#ff5c33', fontWeight:'bold'}}>Business Analytics</span> course focused on data mining and analytics.
            In the course we relied heavily on the software tool <span style={{color:'#ff5c33', fontWeight:'bold'}}>RapidMiner</span> which I had to learn and master.
            Furthermore, I had to grade students, catch their mistakes, and help them improve.
          </motion.p>
          <motion.p className="education-text-1-mobile-end"
          key="/experience_text_14"
          initial={{ opacity: 0, x:0, y:20 }}
          animate={{ opacity: 1, x:0, y:0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}>
            Besides teaching, I also assisted with research.
            I was tasked with doing research on possible improvements in the business program as a whole and the potential of introducing a new bachelor's degree program focused on data analytics.
            I had to look at similar universities to evaluate the possibility of creating such a program.
          </motion.p>
      </div>
	  </div>
  );
}

export default ExperienceMobile;
