import React, {useEffect} from 'react';
import { motion } from "framer-motion";
import FadeIn from "react-lazyload-fadein";
import Fade from 'react-reveal/Fade';
import ReactGA from 'react-ga';

function AwardsMobile() {

	useEffect(() => {
		ReactGA.initialize('UA-176007228-1');
    ReactGA.pageview('Awards');
	}, []);

  return (
    <div className="container-mobile">
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/awards_text_h"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
          Awards
        </motion.h1>
        <motion.p className="education-text-1-mobile"
        key="/awards_text_1"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}>
        I have always maintained the belief that anything can be achieved through hard work and dedication.
        Combining that belief with passion and excitement for my studies, I have been able to deliver projects and produce results of high quality.
        </motion.p>
        <motion.p className="education-text-1-mobile"
        key="/awards_text_2"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}>
        I have been fortunate enough to have been recognized for my efforts and have received numerous awards and scholarships related to both my engineering degree and my MBA, which are detailed on this page.
        </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="awards_mobile_container"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <div className="education_mobile">
            <FadeIn height={400} duration={200}>
            {onLoad =>
              <motion.img style={{height: 75, marginTop: 87.5}}
              src="/awards/automation_region.png" alt=""
              draggable="false"
              onLoad={onLoad} />
            }
            </FadeIn>
        </div>
      </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/awards_text_h_2"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	Automation Student 2019
        </motion.h1>
        <motion.p className="education-text-1-mobile"
        key="/awards_text_3"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}>
        I did my master's thesis at ABB with a focus on reinforcement learning and teaching a robot to find optimized solutions in RobotStudio.
        </motion.p>
        <motion.p className="education-text-1-mobile"
        key="/awards_text_4"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}>
          The work was submitted to Automation Region, a Swedish organization that focuses on industrial automation, and it won the award of the best automation related master thesis of the year in Sweden.
          Read more about the work and the award <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#ffbf00', fontWeight:'bold'}} href="https://www.automationregion.com/arbete-om-optimering-av-kollaborativa-robotar-vinnare-i-automation-student/#.XZV77UjDPzE.linkedin">
          here</a>.
        </motion.p>
      </div>
      <Fade right duration={1000}>
      <motion.div className="awards_mobile_container"
      transition={{ duration: 1.0, delay: 0 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
        <div className="education_mobile">
              <img style={{height: 150, marginTop: 50}}
              src="/awards/nau_franke.png" alt=""
              draggable="false"/>
        </div>
      </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/awards_text_h_3"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	W.A. Franke College of Business
        </motion.h1>
        <motion.p className="education-text-1-mobile"
        key="/awards_text_5"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}>
        Once every year in Northern Arizona University, each faculty gets to award one student <span style={{color:'#ffbf00', fontWeight:'bold'}}>Graduate Student of the Month</span>.
        I was nominated by a professor and I was awarded the honor for the W.A. Franke College of Business.
        </motion.p>
        <motion.p className="education-text-1-mobile"
        key="/awards_text_6"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}>
        Furthermore, the MBA program gives out two awards each year: <span style={{color:'#ffbf00', fontWeight:'bold'}}>Outstanding MBA Student of the Year</span>, and the <span style={{color:'#ffbf00', fontWeight:'bold'}}>MBA Association Award for Student Excellence</span>.
        The recipient of the former is chosen by faculty while the latter is chosen by the students of the MBA cohort. I received both awards for my MBA year.
        </motion.p>
      </div>
      <Fade right duration={1000}>
        <motion.div className="awards_mobile_container"
        transition={{ duration: 1.0, delay: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, x:-700, y:0, transition: {staggerChildren: 0.5} }}>
          <div className="education_mobile">
                <img style={{height: 100, marginTop: 75}}
                src="/awards/wallenberg2.png" alt=""
                draggable="false"/>
          </div>
        </motion.div>
      </Fade>
      <div className="text-container-mobile">
        <motion.h1 className="education-text-h-mobile"
        style={{marginTop: 25}}
        key="/awards_text_h_4"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}>
    	  	Dr. Tech. Marcus Wallenberg Foundation
        </motion.h1>
        <motion.p className="education-text-1-mobile"
        key="/awards_text_7"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}>
          The Dr. Tech. Marcus Wallenberg Foundation for Education in International Industrial Entrepreneurship funds scholarships for master studies at well-reputed universities outside Sweden.
        </motion.p>
        <motion.p className="education-text-1-mobile-end"
        key="/awards_text_8"
        initial={{ opacity: 0, x:0, y:20 }}
        animate={{ opacity: 1, x:0, y:0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}>
          I received the award in 2019 when I decided to pursue an MBA at Northern Arizona University.
          The award covered all my expenses at NAU and enabled me to complete my studies without any financial worry. Read more about the award <a className="text_link_1" target="_blank" rel="noopener noreferrer" style={{color:'#ffbf00', fontWeight:'bold'}} href="https://tmw.wallenberg.org/en">
          here</a>.
        </motion.p>
      </div>
	  </div>
  );
}

export default AwardsMobile;
