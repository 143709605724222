import React, {useEffect} from 'react';
import {
	Switch,
	useLocation,
	Route
} from 'react-router-dom';
import { AnimatePresence } from "framer-motion";
import ReactGA from 'react-ga';

//components

import Header from './components/headerComponent/headerMobile';
import Homepage from './components/pages/homePageMobile';
import Profile from './components/pages/profileMobile';
import Education from './components/pages/educationMobile';
import Experience from './components/pages/experienceMobile';
import Awards from './components/pages/awardsMobile';
import Contact from './components/pages/contactMobile';

//includes
import './Assets/css/default.min.css';

function Mobile() {
	const location = useLocation();

	useEffect(() => {
		ReactGA.initialize('UA-176007228-1');
	}, []);

  return (
	<div className="Mobile">

		<Header />

		<AnimatePresence exitBeforeEnter>
		  <Switch location={location} key={location.pathname}>

				<Route exact path='/' component={Homepage} />
				<Route exact path='/Profile' component={Profile} />
				<Route exact path='/Education' component={Education} />
				<Route exact path='/Experience' component={Experience} />
				<Route exact path='/Awards' component={Awards} />
				<Route exact path='/Contact' component={Contact} />

		  </Switch>
		</AnimatePresence>

	</div>
  );
}

export default Mobile;
